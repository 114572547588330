
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default.scss';
@import "~bootstrap/dist/css/bootstrap.css";



// Font faces

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Black.woff2') format('woff2'),
        url('/assets/fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('/assets/fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Italic.woff2') format('woff2'),
        url('/assets/fonts/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('/assets/fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Light.woff2') format('woff2'),
        url('/assets/fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('/assets/fonts/Lato-HairlineItalic.woff2') format('woff2'),
        url('/assets/fonts/Lato-HairlineItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}



// Css common
body{
    font-family: lato, sans-serif;
}


/*Overdiding material default css*/

.custom-mat-form-field.mat-form-field {
    width: 100%
}

input::-webkit-input-placeholder {
    color: #9C9C9C !important;
    font-size: 12px !important;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    color: #9C9C9C;
    font-size: 12px !important;

}

input:-ms-input-placeholder {
    /* IE 10+ */
    color: #9C9C9C;
    font-size: 12px !important;

}

input:-moz-placeholder {
    /* Firefox 18- */
    color: #9C9C9C;
    font-size: 12px !important;

}

.custom-mat-form-field.custom-datpicker .mat-form-field-suffix {
    top: 0 !important;
}

.custom-mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 50px 0 0 50px !important;
    width: 25px !important;
}

.custom-mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 50px 50px 0 !important;
    width: 18px !important;
}

.custom-mat-form-field.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #F4F4F4;
}

.custom-mat-form-field.mat-form-field-appearance-outline {

    .mat-form-field-prefix,
    .mat-form-field-suffix {
        top: -2px;
    }

    .mat-form-field-infix {
        top: -6px;
        padding: 10px 0;
    }

    .mat-form-field-flex {
        height: 50px;
        padding: 2px 30px !important;
    }

    .mat-select-arrow-wrapper {
        // transform: translateY(-25%);
        transform: none;
    }

    .mat-form-field-outline-thick {
        color: grey;
    }

    /* datepicker icon colcor */
    .mat-datepicker-toggle-default-icon {
        color: #FF6A00 !important;
    }

    .mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
        color: #e6dcdc !important;
    }

    .mat-datepicker-toggle-default-icon {
        margin: auto;
        /* Below color is set as per design but will need to be changed as per given design spec */
        color: #a9a297 !important;
    }

    .error-text {
        background: #FEE1E1;
        color: #E53535;
        border-radius: 15px;
    }
    
    .mat-input-element::placeholder {
        font-size: 14px !important;
    }
}

.custom-mat-form-field.mat-form-field .mat-form-field-wrapper {
    padding-bottom: 15px;
}

.custom-radio-btn .mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #8F8F8F;


}

.custom-radio-btn .mat-accent .mat-radio-inner-circle {
    background: #0480e6;

}

/* To change mat-checkbox background color after checkbox is checked the background color should be blue-clr */
.custom-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #0480e6;
    transition: none !important;
    box-shadow: none !important;
    
}
.custom-checkbox {
    margin-left:10px;
    line-height: 1.75;
}

.label_class{
    padding-left: 20px;
    font-weight: 400;
}




/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
